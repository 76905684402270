import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/auth/AuthService';
import { getIntegrations } from '../services/api/ApiService'; // Adjust the import path as necessary

function Integrations() {
  const navigate = useNavigate();
  const { userDetails } = useAuth(); // Access userDetails via useAuth
  const [integrations, setIntegrations] = useState([]);
  const [googleIntegrations, setGoogleIntegrations] = useState([]);
  const [isGoogleIntegrated, setIsGoogleIntegrated] = useState(false);
  const [isGoogleExpanded, setIsGoogleExpanded] = useState(false);

  const fetchIntegrations = async () => {
    const integrations = await getIntegrations();
    setIntegrations(integrations.data);
  };
  useEffect(() => {
    fetchIntegrations().then(null);
  }, []);

  useEffect(() => {
    if (integrations.length > 0) {
      const googleIntegrations = integrations.filter(
        (integration) => integration.type === 'gmail' && integration.status === 'active',
      );
      setGoogleIntegrations(googleIntegrations);
    }
  }, [integrations]);

  useEffect(() => {
    if (googleIntegrations.length > 0) {
      setIsGoogleIntegrated(true);
      setIsGoogleExpanded(true);
    }
  }, [googleIntegrations]);

  const handleAccordionChange = () => {
    setIsGoogleExpanded(!isGoogleExpanded);
  };

  const handleGoogleIntegration = () => {
    navigate('/google/begin-integration');
  };
  const handleGoogleReintegration = () => {
    navigate('/google/refresh-integration');
  };
  const handleRemoveIntegration = async (integrationId) => {
    navigate('/google/remove-integration/' + integrationId);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" sx={{ p: 2 }}>
        Integrations
      </Typography>
      <Typography variant="body1" component="h1" sx={{ p: 2 }}>
        Here you can manage the integrated services you can use speedable with.
      </Typography>
      <Box sx={{ p: 2 }}>
        {/*<Accordion>*/}
        <Accordion expanded={isGoogleExpanded} onChange={handleAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography>
                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} /> Gmail
              </Typography>
              {isGoogleIntegrated && (
                <Box id="google-integration" display="flex" alignItems="center" sx={{ marginRight: 2 }}>
                  <Typography variant="subtitle1" component="span" sx={{ mr: 1 }}>
                    Integrated
                  </Typography>
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
              {isGoogleIntegrated ? (
                <>
                  <Box>
                    {googleIntegrations.map((integration) => (
                      <Box
                        key={integration.id}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        p={1}
                        border="1px solid #ccc"
                        borderRadius={1}
                        mb={2}
                        width="100%"
                      >
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontWeight: 'bold', color: '#333', flexGrow: 1 }}
                        >
                          {integration.email}
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontWeight: 'bold', color: '#333', flexGrow: 1 }}
                        >
                          {integration.last_active}
                        </Typography>
                        {integration.status === 'active' ? (
                          <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                        ) : (
                          <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
                        )}

                        <Button
                          variant="contained"
                          startIcon={<FontAwesomeIcon icon={faGoogle} style={{ color: 'white' }} />}
                          onClick={handleGoogleReintegration}
                          size="small"
                          sx={{
                            backgroundColor: '#1a73e8',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: '#135ba1',
                            },
                          }}
                        >
                          Refresh
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleRemoveIntegration(integration.id)}
                          size="small"
                          sx={{ ml: 1 }}
                        >
                          Remove
                        </Button>
                      </Box>
                    ))}
                    <Button
                      variant="contained"
                      startIcon={<FontAwesomeIcon icon={faGoogle} style={{ color: 'white' }} />}
                      onClick={() => handleGoogleIntegration()}
                      sx={{
                        backgroundColor: '#1a73e8', // Google's blue color
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#135ba1',
                        },
                      }}
                    >
                      Add another Gmail account
                    </Button>
                  </Box>
                </>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faGoogle} style={{ color: 'white' }} />}
                  onClick={() => handleGoogleIntegration()}
                  sx={{
                    backgroundColor: '#1a73e8', // Google's blue color
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#135ba1',
                    },
                  }}
                >
                  'Integrate with Google'
                </Button>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        {/*<Accordion>*/}
        {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">*/}
        {/*    <Typography>*/}
        {/*      <FontAwesomeIcon icon={faHubspot} style={{ marginRight: 8 }} /> HubSpot*/}
        {/*    </Typography>*/}
        {/*  </AccordionSummary>*/}
        {/*  <AccordionDetails>*/}
        {/*    <Typography>HubSpot integration coming soon.</Typography>*/}
        {/*  </AccordionDetails>*/}
        {/*</Accordion>*/}
        {/*<Accordion>*/}
        {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">*/}
        {/*    <Typography>*/}
        {/*      <FontAwesomeIcon icon={faMailchimp} style={{ marginRight: 8 }} /> MailChimp*/}
        {/*    </Typography>*/}
        {/*  </AccordionSummary>*/}
        {/*  <AccordionDetails>*/}
        {/*    <Typography>MailChimp integration coming soon.</Typography>*/}
        {/*  </AccordionDetails>*/}
        {/*</Accordion>*/}
      </Box>
    </Box>
  );
}

export default Integrations;
