import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, TextField, Button, Paper, Typography, Container, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/auth/AuthService';
import { getUserStatus } from '../services/api/ApiService';

function Login() {
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    document.body.style.transition = 'background-color 1s ease'; // Smooth transition for 2 seconds
    document.body.style.backgroundColor = theme.palette.primary.light;

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.transition = '';
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = [];

    if (!email) {
      errors.push('Please enter an email.');
    }

    if (!password) {
      errors.push('Please enter a password.');
    }

    if (errors.length > 0) {
      setErrorMsg(errors.join('\n'));
      return;
    }

    const userStatus = await getUserStatus(email);
    if (userStatus === 'UNCONFIRMED') {
      navigate(`/signup?email=${email}&password=${password}&verificationRequired=true`);
      return;
    }
    try {
      await signIn(email, password);
      navigate('/campaigns/');
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  return (
    <Container component="main" style={{ height: '100vh' }}>
      <Grid container style={{ height: '100%' }} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{
              fontSize: 300,
              color: '#fff', // Adjust color as needed
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6} container alignItems="center" justifyContent="center">
          <Paper elevation={6} style={{ padding: 32, width: '100%', maxWidth: 400 }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Speedable
            </Typography>
            <Button
              type="button"
              id="signup"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ marginTop: 24 }}
              onClick={() => navigate('/signup')}
            >
              Create account
            </Button>
            <div style={{ display: 'flex', alignItems: 'center', margin: '24px 0' }}>
              <div style={{ flexGrow: 1, height: '1px', backgroundColor: '#ccc' }}></div>
              <span style={{ margin: '0 16px', color: '#666' }}>or</span>
              <div style={{ flexGrow: 1, height: '1px', backgroundColor: '#ccc' }}></div>
            </div>

            <form onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {errorMsg && (
                <div
                  style={{
                    marginTop: 16,
                    padding: 8,
                    fontWeight: 'bold',
                    fontSize: 12,
                    color: 'red',
                    backgroundColor: '#FFCDD2',
                    borderRadius: 8,
                  }}
                >
                  {' '}
                  {errorMsg}
                </div>
              )}
              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 24 }}>
                Login
              </Button>
            </form>
            <Typography variant="body2" align="center" style={{ marginTop: 16 }}>
              <Link component={RouterLink} to="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
