import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Button } from '@mui/material';
import { saveGoogleTokens } from '../../../services/api/ApiService';

function GoogleOAuthRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState({ state: 'loading', message: '' });
  const isProcessing = useRef(false); // Add this ref to track processing state

  useEffect(() => {
    // Prevent double execution
    if (isProcessing.current) return;
    isProcessing.current = true;

    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const error = params.get('error');

    if (error || !code) {
      console.error('Google OAuth error:', error);
      setStatus({ state: 'error', message: 'Failed to authenticate with Google. Please try again.' });
      return;
    }

    const saveTokens = async () => {
      try {
        const resp = await saveGoogleTokens(code);
        setStatus({ state: 'success', message: resp.message });
      } catch (error) {
        setStatus({ state: 'error', message: 'Failed to save Google OAuth tokens. Please try again.' });
      }
    };

    saveTokens();
  }, [location]);

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" component="h1">
          Welcome back!
        </Typography>
        {status.state === 'loading' && <Typography>Loading...</Typography>}
        {status.state === 'success' && <Typography sx={{ color: 'green' }}>{status.message}</Typography>}
        {status.state === 'error' && <Typography sx={{ color: 'red' }}>{status.message}</Typography>}
        <Button variant="contained" onClick={() => navigate('/campaigns')} sx={{ mt: 2 }}>
          Use your new integration in your campaigns.
        </Button>
      </Box>
    </Container>
  );
}

export default GoogleOAuthRedirect;
