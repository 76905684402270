import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, Paper, Typography, Container, Checkbox, FormControlLabel, Link } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/auth/AuthService';
import { getUserStatus } from '../services/api/ApiService';

function Login() {
  const params = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [company, setCompany] = useState('');
  const [location, setLocation] = useState('');
  const { signUp, confirmUser, signIn } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    document.body.style.transition = 'background-color 1s ease'; // Smooth transition for 2 seconds
    document.body.style.backgroundColor = theme.palette.primary.light;

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.transition = '';
    };
  }, []);

  useEffect(() => {
    if (params.get('email') && params.get('password') && params.get('verificationRequired')) {
      setEmail(params.get('email'));
      setPassword(params.get('password'));
      setIsConfirming(true);
    }
  }, [params]);

  const handleConfirm = async (event) => {
    event.preventDefault();

    if (!verificationCode) {
      setErrorMsg('Please enter a verification code.');
      return;
    }

    try {
      await confirmUser(email, verificationCode);
      await signIn(email, password, location, company);
      navigate('/campaigns/');
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = [];

    if (!email) {
      errors.push('Please enter an email.');
    }

    if (!password) {
      errors.push('Please enter a password.');
    }

    if (!confirmPassword) {
      errors.push('Please confirm your password.');
    }

    if (password && confirmPassword && password !== confirmPassword) {
      errors.push('Passwords do not match.');
    }

    if (!acceptTerms) {
      errors.push('Please accept the terms of service and privacy policy.');
    }

    if (errors.length > 0) {
      setErrorMsg(errors.join('\n'));
      return;
    }

    const userStatus = await getUserStatus(email);
    if (userStatus === 'CONFIRMED') {
      try {
        await signIn(email, password, location, company);
        navigate('/dashboard');
      } catch (error) {
        setErrorMsg(error.message);
      }
      return;
    }

    if (userStatus === 'UNCONFIRMED') {
      setIsConfirming(true);
      return;
    }

    try {
      await signUp(email, password);
      setIsConfirming(true);
      setErrorMsg('');
    } catch (error) {
      setErrorMsg(error.message);
      // Clear sensitive data in case of an error
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setCompany('');
      setLocation('');
      setAcceptTerms(false);
    }
  };

  return (
    <Container component="main" style={{ height: '100vh' }}>
      <Grid container style={{ height: '100%' }} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{
              fontSize: 300,
              color: '#fff', // Adjust color as needed
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6} container alignItems="center" justifyContent="center">
          <Paper elevation={6} style={{ padding: 32, width: '100%', maxWidth: 400 }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Speedable
            </Typography>
            {isConfirming ? (
              <ConfirmUserForm
                handleConfirm={handleConfirm}
                verificationCode={verificationCode}
                errorMsg={errorMsg}
                setVerificationCode={setVerificationCode}
              />
            ) : (
              <SignUpUserForm
                handleSubmit={handleSubmit}
                setEmail={setEmail}
                email={email}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                setAcceptTerms={setAcceptTerms}
                acceptTerms={acceptTerms}
                errorMsg={errorMsg}
                location={location}
                setLocation={setLocation}
                company={company}
                setCompany={setCompany}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

function SignUpUserForm({
  handleSubmit,
  setEmail,
  email,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  setAcceptTerms,
  acceptTerms,
  errorMsg,
  location,
  setLocation,
  company,
  setCompany,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        id="confirmPassword"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="company"
        label="Company"
        type="text"
        id="company"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="location"
        label="Location"
        type="text"
        id="location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="acceptTerms"
            id="accept-terms"
            value={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
          />
        }
        label={
          <Typography>
            I accept the{' '}
            <Link href="https://www.speedable.io/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link href="https://www.speedable.io/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
            .
          </Typography>
        }
      />

      {errorMsg && (
        <Typography
          style={{
            whiteSpace: 'pre-wrap',
            marginTop: 16,
            padding: 8,
            fontWeight: 'bold',
            fontSize: 12,
            color: 'red',
            backgroundColor: '#FFCDD2',
            borderRadius: 8,
          }}
        >
          {' '}
          {errorMsg}
        </Typography>
      )}
      <Button
        type="submit"
        id="create-account-btn"
        fullWidth
        variant="contained"
        color="primary"
        style={{ marginTop: 24 }}
      >
        Create account
      </Button>
    </form>
  );
}

function ConfirmUserForm({ handleConfirm, verificationCode, setVerificationCode, errorMsg }) {
  return (
    <form onSubmit={handleConfirm}>
      <Typography variant="p" component="p" gutterBottom>
        Please check your email for your verification code.
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="verification-code"
        label="Verification Code"
        name="verificationCode"
        autoFocus
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      {errorMsg && (
        <Typography
          style={{
            whiteSpace: 'pre-wrap',
            marginTop: 16,
            padding: 8,
            fontWeight: 'bold',
            fontSize: 12,
            color: 'red',
            backgroundColor: '#FFCDD2',
            borderRadius: 8,
          }}
        >
          {' '}
          {errorMsg}
        </Typography>
      )}
      <Button
        type="submit"
        id="create-account-btn"
        fullWidth
        variant="contained"
        color="primary"
        style={{ marginTop: 24 }}
      >
        Verify
      </Button>
    </form>
  );
}

export default Login;
