import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, Typography, Badge } from '@mui/material';
import RedeemIcon from '@mui/icons-material/Redeem';
import { useTheme } from '@mui/material/styles';
import { getRewards } from '../services/api/ApiService';
import { useLocation } from 'react-router-dom';

const RewardsListItem = ({ handleNavigate }) => {
  const [rewardCount, setRewardCount] = useState(0); // Total rewards count
  const [lastVisitedRewardCount, setLastVisitedRewardCount] = useState(
    parseInt(localStorage.getItem('lastVisitedRewardCount'), 10) || 0
  ); // Last known rewards count from localStorage
  const [notificationCount, setNotificationCount] = useState(0); // New rewards since last visit
  const theme = useTheme();
  const location = useLocation();

  // Poll rewards from the backend, but only if we're not on the rewards page
  useEffect(() => {
    const pollRewards = async () => {
      try {
        const rewards = await getRewards();
        const newRewardCount = rewards.rewards.length || 0;

        // Log the reward counts for debugging

        // Update the reward count
        setRewardCount(newRewardCount);

        // Calculate how many new rewards have been added since the last visit
        if (newRewardCount > lastVisitedRewardCount) {
          setNotificationCount(newRewardCount - lastVisitedRewardCount);
        }
      } catch (error) {
        console.error('Error fetching rewards:', error);
      }
    };

    // Poll every 2 seconds (as requested)
    const interval = setInterval(pollRewards, 30000);

    // Initial poll
    pollRewards();

    return () => clearInterval(interval);
  }, [lastVisitedRewardCount, location.pathname]);

  // Handle when the rewards page is visited
  const handleNavigateReward = async () => {
    setLastVisitedRewardCount(rewardCount); // Update the last visited count
    localStorage.setItem('lastVisitedRewardCount', rewardCount); // Save to localStorage
    setNotificationCount(0); // Reset notification count
    handleNavigate('/rewards');
  };

  return (
    <ListItem
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.primary.light,
        },
      }}
      button
      onClick={() => handleNavigateReward()}
    >
      <ListItemIcon>
        <Badge
          badgeContent={notificationCount > 0 ? notificationCount : null}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#FF3B30', // Custom red color similar to Facebook notifications
              color: 'white',
            },
          }}
          overlap="circular"
        >
          <RedeemIcon />
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography sx={{ fontSize: 18, p: 1 }} variant="body1">
            Rewards
          </Typography>
        }
      />
    </ListItem>
  );
};

export default RewardsListItem;