import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { getUserStatus, createUser, getUserByCognitoSub, getUserCredits } from '../api/ApiService';
import cognitoConfig from '../../config/aws-cognito-config';

export const userPool = new CognitoUserPool(cognitoConfig);

const createCognitoUser = (email) => {
  return new CognitoUser({
    Username: email,
    Pool: userPool,
  });
};

const handleCognitoPromise = (action) => {
  return new Promise((resolve, reject) => {
    action({
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export const requestResetPassword = async (email) => {
  const cognitoUser = createCognitoUser(email);
  return handleCognitoPromise((callbacks) => cognitoUser.forgotPassword(callbacks));
};

export const resetPassword = async (email, confirmationCode, password) => {
  const cognitoUser = createCognitoUser(email);
  return handleCognitoPromise((callbacks) => cognitoUser.confirmPassword(confirmationCode, password, callbacks));
};

export const confirmUser = async (email, confirmationCode) => {
  const cognitoUser = createCognitoUser(email);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        return reject(err);
      }
      resolve(result);
    });
  });
};

export const signUp = async (email, password, setUserDetails) => {
  const attributeList = [{ Name: 'email', Value: email }];

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, async (err, result) => {
      if (err) {
        return reject(err);
      }
      resolve(result);
    });
  });
};

export const signIn = async (email, password, location, company, setUserDetails, setUserCredits) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const cognitoUser = createCognitoUser(email);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      async onSuccess(result) {
        const jwtToken = result.getIdToken().getJwtToken();
        localStorage.setItem('jwtToken', jwtToken);
        localStorage.setItem('email', email);

        const cognitoSub = result.idToken.payload.sub;
        let userDetail = null;

        try {
          userDetail = await getUserByCognitoSub(cognitoSub);
          const _userCredits = await getUserCredits(userDetail.id);
          setUserCredits(_userCredits);
        } catch (error) {
          if (error.message.includes('404')) {
            try {
              userDetail = await createUser({ cognito_sub: cognitoSub, email, location, company });
            } catch (createError) {
              return reject(createError);
            }
          } else {
            return reject(error);
          }
        }

        userDetail['email'] = email;
        setUserDetails(userDetail);
        resolve(userDetail);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const deleteUser = async () => {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) {
    return Promise.reject(new Error('No current user found'));
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        return reject(err);
      }

      cognitoUser.deleteUser((deleteErr, result) => {
        if (deleteErr) {
          return reject(deleteErr);
        }
        resolve(result);
      });
    });
  });
};
