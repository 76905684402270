import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getRecipientsByThreadId, getThread } from '../../services/api/ApiService';

function ThreadDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [thread, setThread] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchThreadDetails = async () => {
      setIsLoading(true);
      try {
        const fetchedThread = await getThread(id);
        const fetchedRecipients = await getRecipientsByThreadId(id);
        setThread(fetchedThread);
        setRecipients(fetchedRecipients);
      } catch (error) {
        console.error('Failed to fetch thread details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchThreadDetails();
  }, [id]);

  return (
    <Box sx={{ p: 4 }}>
      {isLoading ? (
        <CircularProgress />
      ) : thread ? (
        <>
          <Box display="flex" justifyContent="space-between" sx={{ pb: 1, borderBottom: '1px solid #ccc', mb: 2 }}>
            <Typography variant="h4" component="h1">
              Thread: {thread.summary}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/campaigns/' + thread.campaign_id)}
              sx={{ mb: 2, fontWeight: 'bold' }}
            >
              Back
            </Button>
          </Box>
          <Typography variant="body1" sx={{ my: 1 }}>
            Created At: {new Date(thread.created_at).toLocaleDateString()}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            From Email: {thread.from_email}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Status: {thread.status}
          </Typography>
          {/* Additional thread details can be listed here */}

          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            Recipients
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="recipients">
              <TableHead>
                <TableRow>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Reply Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipients.map((recipient) => (
                  <TableRow key={recipient.id}>
                    <TableCell>{recipient.email_address}</TableCell>
                    <TableCell>
                      {recipient.reply_timestamp ? new Date(recipient.reply_timestamp).toLocaleString() : 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="body1">Thread not found.</Typography>
      )}
    </Box>
  );
}

export default ThreadDetail;
