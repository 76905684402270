import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Container, CircularProgress } from '@mui/material';
import { getGoogleIntegrationURL, removeGoogleIntegration } from '../../../services/api/ApiService'; // Adjust the import path as needed

function GoogleOAuthRemoveRedirect() {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const fetchGoogleAuthUrl = async () => {
      console.log('Fetching Google OAuth URL...');
      try {
        const response = await removeGoogleIntegration(integrationId);
        console.log('Redirecting to Google OAuth URL:', response.auth_url);
        if (response.status === 'success') {
          navigate('/integrations/');
        }
      } catch (error) {
        console.error('Failed to fetch Google OAuth URL:', error);
      }
    };

    fetchGoogleAuthUrl();
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4, alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Redirecting to Google...
        </Typography>
        <CircularProgress />
      </Box>
    </Container>
  );
}

export default GoogleOAuthRemoveRedirect;
